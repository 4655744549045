import React from 'react'
import productmain from '../../../static/images/productmain.png'

export default function servicesMain() {
  return (
    <div>

<section className=' px-4 lg:px-32 bg-white' style={{fontFamily:'poppins'}}>
  {/* <div class="skew skew-top mr-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-50" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
    </svg>
  </div> */}
  <div class="skew skew-top ml-for-radius">
    <svg class="h-8 md:h-12 lg:h-10 w-full text-gray-50" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
    </svg>
  </div>
  <div class="pt-20 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto  lg:px-4">
      <div class="mb-10 flex flex-wrap">
      <div class="relative w-full lg:w-1/2 lg:mb-20">
          <img class=" relative rounded-lg lg:w-full lg:h-full " src={productmain} alt=""/>
          
        </div>

        <div class="mb-10 lg:mb-0 lg:mt-10 w-full lg:w-1/2">
          <div class="max-w-xl pl-8 lg:pl-14">
            {/* <span class="text-sky-600 font-semibold">Dolor sit amet consectutar</span> */}
            <h2 class="mb-8 text-2xl lg:text-5xl font-bold font-heading text-indigo-900">Simplified analytics and modern technologies</h2>
            <p class="max-w-xl text-gray-500 leading-loose">Future is uncertain and complex, but with simplified yet modern solutions driven by data can mitigate all the risks.</p>
          </div>
        </div>
        
      </div>
      


    </div>
  </div>

  <div class="skew skew-bottom ml-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-50" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
    </svg>
  </div>
  <h2 class="mb-8 text-2xl lg:text-5xl lg:text-center font-bold font-heading text-indigo-900 mr-6 lg:mr-0">Complex problems, Simple Solution</h2>

  <div class="flex flex-wrap pt-10 py-10 lg:py-20 px-5 -mx-4">

        <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/4 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">1</span>
          <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">Underwriting Score Model</h3>
          <p class="text-gray-500 leading-loose">The Underwriting Score Model application is based on principles of multivariate regression & used to compute risk premium based on a predictive model.</p>
        </div>
        <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/4 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">2</span>
          <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">i-IFRS17</h3>
          <p class="text-gray-500 leading-loose">It is a modern software to perform end to end implementation of IFRS17 for an insurance organisation. It has ability to create different line of businesses</p>
        </div>
        <div class="w-full lg:w-1/4 px-4">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">3</span>
          <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">CSM Engine</h3>
          <p class="text-gray-500 leading-loose">CSM Engine is a computation tool designed to generate CSM results based on the input data 
          provided. This can be used as a REST API via any software.</p>
        </div>
        <div class="w-full lg:w-1/4 px-4 mt-10 lg:mt-0">
          <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">4</span>
          <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">UnLimit</h3>
          <p class="text-gray-500 leading-loose">UnLimit is a web-based portal which provides solutions for all your
        organizational Employee benefits, health & wellness needs consolidated in a single platform.</p>
        </div>
        
      </div>
</section>

    </div>
  )
}
